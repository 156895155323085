import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Jacob Harry Thomas</h2>
        <p><a href="mailto:unity@jacobhthomas.com">unity@jacobhthomas.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Jacob. I am an applied math student and physics researcher
        at the Illinois Institute of Technology pursuing the field of quantum
        computation and science research. I am interested in consilience,
        mathematics, philosophy, biology, computology, psychology, economics, sociology,
        linguistics, and art.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/about') ? <Link to="/about" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Jacob Harry Thomas <Link to="/">jacobhthomas.com</Link>.</p>
    </section>
  </section>
);

export default SideBar;
